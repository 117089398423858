/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/affordable/'], ['en', 'https://www.hear.com/hearing-aids/affordable/'], ['en-US', 'https://www.hear.com/hearing-aids/affordable/'], ['en-CA', 'https://ca.hear.com/hearing-aids/affordable/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-best-investment-you-could-ever-make",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-best-investment-you-could-ever-make",
    "aria-label": "the best investment you could ever make permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The best investment you could ever make"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/prices/",
    className: "c-md-a"
  }, "Hearing aids are an important investment"), " that will dramatically affect your quality of life. In fact, hearing care professionals encourage patients ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "to treat their hearing loss"), " sooner than later as the benefits are felt from the moment they begin wearing hearing aids. That being said, these devices are an investment, which, as we all know, means we need to spend money. The old saying “you get what you pay for” is certainly true for hearing aids. Therefore, cheaper is not always better. Cheaper may sometimes cost more in the long run if a product is defective or requires too much maintenance to get it working right."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, this doesn’t mean that the most expensive device will guarantee the best results either. The key is to find a balance between what you actually need and what you can actually afford. That is why at hear.com, we offer free expert advice over the phone so that you can have a better idea of what would be a good solution for you. In addition, our risk-free trial allows you to try any of our hearing aids for 30 days. That way, you know that you’re getting a good return on your investment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-makes-hearing-aids-affordable",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-makes-hearing-aids-affordable",
    "aria-label": "what makes hearing aids affordable permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What makes hearing aids “affordable?\""), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The obvious answer is, a hearing aid is affordable when it fits into your budget. But keep in mind that just because something is affordable doesn’t mean that it is suitable for you. Oftentimes, people settle for less because it’s cheaper. They’ll look online in places like eBay and Amazon ", React.createElement(_components.a, {
    href: "/hearing-aids/used",
    className: "c-md-a"
  }, "for used or refurbished products"), ", only to be met with disappointment when the devices fail to function properly. This is because these “affordable” hearing aids don’t include the services of a trained hearing specialist or audiologist who would fit and customize the devices to meet their needs. Essentially, what they end up with is a hearing amplifier that increases the volume of sounds indiscriminately. Instead of hearing what they want to hear, everything around them will sound louder, making it difficult to understand speech in noisy environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "from-smart-phones-to-smart-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#from-smart-phones-to-smart-hearing-aids",
    "aria-label": "from smart phones to smart hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "From smart phones to smart hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most of us are familiar with the advancements made in mobile phone technology. The latest iOS and Android devices are loaded with advanced features, ", React.createElement(_components.a, {
    href: "/hearing-aids/bluetooth",
    className: "c-md-a"
  }, "like wireless connectivity"), " and much more. However, not everyone needs an expensive, high-tech phone. Some people would rather spend hundreds of dollars less for a basic phone that provides them with all the standard features and functions. Similarly, an affordable hearing aid should be tailored to your individual hearing preferences, yet considerate of your budget. In other words, if your lifestyle demands a premium device with all the bells and whistles, then you should go for a high-end product. On the other hand, if you are less active and spend a lot of time indoors, a basic or mid-range device may work for you. Once you’ve decided on the right model, the next step is to work with your hearing care professional to find the best and most affordable option."), "\n", React.createElement(ButtonCta, {
    copy: "FIND AFFORDABLE HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aid-affordability-plans",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-affordability-plans",
    "aria-label": "hearing aid affordability plans permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aid Affordability Plans"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we strive to find everyone the best and most affordable hearing loss solution. To this end, we offer a free consultation with one of our hearing aid experts when you contact us. We can gladly go over all your options and work together to find something you can afford. If you need financial assistance, our affordability plans are a great way to make low monthly payments for ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "your hearing aids."), " We can quickly pre-approve you over the phone and connect you with one of our local Partner Providers for an evaluation and hearing aid fitting. We guarantee that your investment will improve your overall quality of life."), "\n", React.createElement(ButtonCta, {
    copy: "FIND AFFORDABLE HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
